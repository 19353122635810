import get from 'lodash/get';
import {
  ACTION_NEEDING_AUTH,
  AUTHENTICATION_SUCCESSFUL,
  AUTHENTICATION_UNSUCCESSFUL,
  AUTH_DONE,
  CREATE_ACCOUNT,
  EMAIL_LOGIN,
  EMAIL_VERIFICATION,
  FORGOT_PASSWORD,
  LOGOUT,
  NEEDS_SIGN_IN_DIALOG,
  NEEDS_SIGN_UP_DIALOG,
  OAUTH_LOGIN,
  RESET_PASSWORD,
  UNAUTHENTICATE,
  UPDATE_VIEW_TYPE,
} from '../actions/auth';
import viewTypes from '../constants/auth/viewTypes';
import getInitialLoginDetails from '../utils/auth/getInitialLoginDetails';
import { fulfilled, pending, rejected } from './utils/asyncActionNameSuffixes';

import createMappedReducer from './utils/createMappedReducer';

const initialState = {
  ...getInitialLoginDetails,
};

function reassessLoginState(state, newState) {
  return {
    ...state,
    isFetching: false,
    ...newState,
  };
}

function authPending(state) {
  return reassessLoginState(state, {
    isFetching: true,
  });
}

function authPartiallyFulfilled(state, action) {
  const [providerName] = action?.meta?.labels || [];
  return reassessLoginState(state, {
    pending: {
      authDialogOpen: false,
      actionNeedingAuth: null,
      failAuthNotice: '',
      successAuthNotice: '',
      signInOption: providerName || state.signInOption,
    },
  });
}

function authFulfilled(state) {
  return reassessLoginState(state, {
    ...state.pending,
    authDialogOpen: false,
    actionNeedingAuth: null,
    failAuthNotice: '',
    successAuthNotice: '',
  });
}

function authRejected(state, action) {
  return reassessLoginState(state, {
    failAuthNotice: get(action, 'meta.failAuthNotice') || '',
  });
}

function passwordActionFulfilled(state, action) {
  return reassessLoginState(state, {
    successAuthNotice: action.meta.successAuthNotice,
  });
}

function unauthFulfilled(state) {
  return authFulfilled(state);
}

export const loginDetails = createMappedReducer(initialState, {
  [pending(EMAIL_LOGIN)]: authPending,
  [fulfilled(EMAIL_LOGIN)]: authPartiallyFulfilled,
  [AUTHENTICATION_SUCCESSFUL]: authFulfilled,
  [AUTHENTICATION_UNSUCCESSFUL]: authRejected,
  [rejected(EMAIL_VERIFICATION)]: authRejected,
  [pending(OAUTH_LOGIN)]: authPending,
  [fulfilled(OAUTH_LOGIN)]: authPartiallyFulfilled,
  [rejected(OAUTH_LOGIN)]: authRejected,
  [pending(CREATE_ACCOUNT)]: authPending,
  [fulfilled(CREATE_ACCOUNT)]: authPartiallyFulfilled,
  [pending(LOGOUT)]: authPending,
  [fulfilled(LOGOUT)]: unauthFulfilled,
  [rejected(LOGOUT)](state) {
    return reassessLoginState(state, {
      authDialogOpen: true,
      viewType: viewTypes.logoutFailure,
    });
  },
  [pending(FORGOT_PASSWORD)]: authPending,
  [fulfilled(FORGOT_PASSWORD)]: passwordActionFulfilled,
  [rejected(FORGOT_PASSWORD)]: authRejected,
  [pending(RESET_PASSWORD)]: authPending,
  [fulfilled(RESET_PASSWORD)](state, action) {
    return reassessLoginState(state, {
      viewType: viewTypes.signIn,
      successAuthNotice: action.meta.successAuthNotice,
    });
  },
  [rejected(RESET_PASSWORD)]: authRejected,
  [NEEDS_SIGN_IN_DIALOG](state) {
    return reassessLoginState(state, {
      authDialogOpen: true,
      viewType: viewTypes.signIn,
    });
  },
  [NEEDS_SIGN_UP_DIALOG](state) {
    return reassessLoginState(state, {
      authDialogOpen: true,
      viewType: viewTypes.signUp,
    });
  },
  [AUTH_DONE]: authFulfilled,
  [ACTION_NEEDING_AUTH](state, action) {
    return reassessLoginState(state, {
      viewType: viewTypes.signIn,
      actionNeedingAuth: action.actionNeedingAuth,
      failAuthNotice: action.failAuthNotice,
    });
  },
  [UPDATE_VIEW_TYPE](state, action) {
    return reassessLoginState(state, {
      viewType: action.viewType,
    });
  },
  [UNAUTHENTICATE]: unauthFulfilled,
});
